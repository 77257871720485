.timeline {
  padding: 0 !important;
}

.timeline .timeline_header {
  padding-top: 7px;
}
.timeline .timeline_first_item {
  min-height: 80px !important;
}
.timeline .MuiTimelineItem-root {
  min-height: 0px;
}
.timeline .MuiTimelineItem-missingOppositeContent:before {
  display: none;
}
.timeline .timeline_dot_header {
  color: black !important;
  background-color: var(--main-color);
  font-size: small !important;
  padding: 10px !important;
}
.timeline .timeline_dot {
  color: black !important;
  border-color: var(--main-color);
  padding: 2px !important;
}

.timeline_header .MuiTimelineConnector:root {
  background-color: #eee;
}
.timeline .timeline_dot_header > .MuiSvgIcon-root {
  font-size: 20px;
  color: darkslategray;
}
.timeline .seperator_padding {
  padding-left: 18px !important;
}
.timeline .timeline_content {
  padding-top: 0px !important;
  margin-bottom: 20px;
}
