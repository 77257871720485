.aboutme_text {
  color: darkslategray;
  font-size: smaller;
  line-height: 22px !important;
  white-space: pre-line;
}

.resume_timeline .timeline_content .timeline_title {
  font-size: 16px !important;
  font-weight: 500;
}

.resume_timeline .timeline_content .timeline_date {
  color: darkslategray;
}
.resume_timeline .timeline_content .timeline_date {
  color: gray;
  font-size: small;
  margin-top: 6px;
}

.resume_timeline .timeline_content .timeline_date > a {
  text-decoration: none;
  color: gray;
  font-size: small;
}
.resume_timeline .timeline_content .timeline_date > a:hover {
  color: darkslategray !important;
}

/* services */
.service {
  height: 100%;
  text-align: center;
  padding-bottom: 10px;
}
.service .service_title {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}
.service .service_description {
  color: #989898;
  border-color: var(--main-color) !important;
  margin-right: 5px;
  text-align: center;
}
.service_icon > .MuiSvgIcon-root {
  font-size: 46px !important;
  margin-left: 0 !important;
  font-weight: 300 !important;
  color: darkslategray;
}

/* Skills */
.skills {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
  height: 100%;
}

.skills .skill_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
}
.skills .skill_description {
  display: flex;
  align-items: center;
  font-weight: 400;
  color: #989898;
  line-height: 18px;
}
.skills .skill_description .timeline_dot {
  border-color: var(--main-color);
  padding: 2px;
  margin-right: 5px !important;
}
.contact_forms .MuiFormControl-root {
  min-width: 100%;
}
.contact_information_item {
  font-size: 14px;
  color: #787878;
}
.contact_information_item > span {
  color: black !important;
  font-weight: 500;
}
