.custom_btn {
  background-color: var(--main-color) !important;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  border-radius: 50px !important;
  padding: 1px 12px;
  line-height: 35px;
  min-width: 100px;
  outline: none;
}

.custom_btn .btn_icon_container {
  color: black;
  background-color: #fff;
  border-radius: 50px !important;
  height: 27px;
  width: 27px;
  line-height: 29px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom_btn .btn_icon_container > .MuiSvgIcon-root {
  font-size: 16px;
}
.custom_btn .btn_text {
  font-size: 14px;
  text-transform: none;
  text-align: center;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
}
