@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Merriweather:wght@300&display=swap);
* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  overflow-x: hidden;
  position: relative;
  background: url(/static/media/bg.54815803.png) #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-color: #ffc500;
}

.top_60 {
  margin-top: 60px !important;
}
.pt_30 {
  padding-top: 30px;
}
.mb_20 {
  margin-bottom: 20px !important;
}
.p_30 {
  padding: 30px !important;
}
.p_50 {
  padding: 50px !important;
}
.pb_45 {
  padding-bottom: 45px !important;
}
.top_30 {
  margin: 30px !important;
}
.mb_30 {
  margin-bottom: 30px !important;
}

@media (max-width: 901px) {
  .top_60 {
    margin-top: 30px;
  }
}
.container_shadow {
  box-shadow: 0px 2px 92px 0px rgba(245, 0, 0, 0.13);
  -webkit-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.13);
}

.main_content {
  background-color: white;
  min-height: 100vh;
  border-radius: 6px;
  -moz-border-radius: 6px;
  -webkit-bok-radius: 6px;
  margin-bottom: 30px;
}

.section_title {
  position: relative;
  display: inline-block;
  padding: 0 3px;
}

.section_title .section_title_text {
  font-size: 18px;
  font-weight: 600;
  position: relative;
  margin: 0;
}

.section_title span {
  position: absolute;
  height: 7px;
  width: 100%;
  bottom: 0;
  opacity: 0.4;
  left: 0;
  background-color: #ffc500;
  background-color: var(--main-color);
}
.section {
  padding-left: 30px;
  padding-right: 30px;
}
/* grap bg */
.graybg {
  background-color: #fafafa;
}

/* tabs */
.customtabs {
}
.customtabs .active {
  color: var(--main-color) !important;
}

.customtabs .customtabs_item {
  padding: 0px !important;
  min-width: 0px !important;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  outline-width: 0px !important;
}

/* cards */
.customcard {
  height: 100%;
  max-width: 350px;
  box-shadow: 0 0 48px 0px rgba(4, 6, 4, 0.08) !important;
  overflow: hidden;
  outline-width: 0px !important;
}
.customcard .customcard_image {
  height: 140px;
}
.customcard .customcard_title {
  font-size: 16px !important;
  font-weight: 500;
}

.customcard .customcard_description {
  color: gray;
  font-weight: 400;
}
/* Dialog */
.projectdialog {
}
.projectdialog .projectdialog_image {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.projectdialog .projectdialog_description {
  margin-top: 10px !important;
}

.projectdialog .projectdialog_title {
  font-weight: 600;
  font-style: bold;
}
.projectdialog .projectdialog_actions {
  -webkit-justify-content: center !important;
          justify-content: center !important;
  margin-bottom: 10px;
}
.projectdialog .projectdialog_icon {
  color: darkslategray;
  margin-bottom: 0px 12px;
  cursor: pointer;
}
.projectdialog .projectdialog_icon :hover {
  color: var(--main-color);
}
.projectdialog .projectdialog_icon > .MuiSvgIcon-root {
  font-size: 30px !important;
}

/* tabs */
.customtabs {
}
.customtabs .active {
  color: var(--main-color) !important;
}

.customtabs .customtabs_item {
  padding: 0px !important;
  min-width: 0px !important;
  margin-right: 20px !important;
  text-transform: inherit !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  outline-width: 0px !important;
}

/* cards */
.customcard {
  height: 100%;
  max-width: 350px;
  box-shadow: 0 0 48px 0px rgba(4, 6, 4, 0.08) !important;
  overflow: hidden;
  outline-width: 0px !important;
}
.customcard .customcard_image {
  height: 140px;
}
.customcard .customcard_title {
  font-size: 16px !important;
  font-weight: 500;
}

.customcard .customcard_description {
  color: gray;
  font-weight: 400;
}
/* Dialog */
.projectdialog {
}
.projectdialog .projectdialog_image {
  max-height: 300px;
  width: 100%;
  object-fit: cover;
  object-position: center center;
}
.projectdialog .projectdialog_description {
  margin-top: 10px !important;
}

.projectdialog .projectdialog_title {
  font-weight: 600;
  font-style: bold;
}
.projectdialog .projectdialog_actions {
  -webkit-justify-content: center !important;
          justify-content: center !important;
  margin-bottom: 10px;
}
.projectdialog .projectdialog_icon {
  color: darkslategray;
  margin-bottom: 0px 12px;
  cursor: pointer;
}
.projectdialog .projectdialog_icon :hover {
  color: var(--main-color);
}
.projectdialog .projectdialog_icon > .MuiSvgIcon-root {
  font-size: 30px !important;
}

.aboutme_text {
  color: darkslategray;
  font-size: smaller;
  line-height: 22px !important;
  white-space: pre-line;
}

.resume_timeline .timeline_content .timeline_title {
  font-size: 16px !important;
  font-weight: 500;
}

.resume_timeline .timeline_content .timeline_date {
  color: darkslategray;
}
.resume_timeline .timeline_content .timeline_date {
  color: gray;
  font-size: small;
  margin-top: 6px;
}

.resume_timeline .timeline_content .timeline_date > a {
  text-decoration: none;
  color: gray;
  font-size: small;
}
.resume_timeline .timeline_content .timeline_date > a:hover {
  color: darkslategray !important;
}

/* services */
.service {
  height: 100%;
  text-align: center;
  padding-bottom: 10px;
}
.service .service_title {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
}
.service .service_description {
  color: #989898;
  border-color: var(--main-color) !important;
  margin-right: 5px;
  text-align: center;
}
.service_icon > .MuiSvgIcon-root {
  font-size: 46px !important;
  margin-left: 0 !important;
  font-weight: 300 !important;
  color: darkslategray;
}

/* Skills */
.skills {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 48px 0px rgba(4, 6, 4, 0.08) !important;
  height: 100%;
}

.skills .skill_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
}
.skills .skill_description {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 400;
  color: #989898;
  line-height: 18px;
}
.skills .skill_description .timeline_dot {
  border-color: var(--main-color);
  padding: 2px;
  margin-right: 5px !important;
}
.contact_forms .MuiFormControl-root {
  min-width: 100%;
}
.contact_information_item {
  font-size: 14px;
  color: #787878;
}
.contact_information_item > span {
  color: black !important;
  font-weight: 500;
}

.timeline {
  padding: 0 !important;
}

.timeline .timeline_header {
  padding-top: 7px;
}
.timeline .timeline_first_item {
  min-height: 80px !important;
}
.timeline .MuiTimelineItem-root {
  min-height: 0px;
}
.timeline .MuiTimelineItem-missingOppositeContent:before {
  display: none;
}
.timeline .timeline_dot_header {
  color: black !important;
  background-color: var(--main-color);
  font-size: small !important;
  padding: 10px !important;
}
.timeline .timeline_dot {
  color: black !important;
  border-color: var(--main-color);
  padding: 2px !important;
}

.timeline_header .MuiTimelineConnector:root {
  background-color: #eee;
}
.timeline .timeline_dot_header > .MuiSvgIcon-root {
  font-size: 20px;
  color: darkslategray;
}
.timeline .seperator_padding {
  padding-left: 18px !important;
}
.timeline .timeline_content {
  padding-top: 0px !important;
  margin-bottom: 20px;
}

.custom_btn {
  background-color: var(--main-color) !important;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  border-radius: 50px !important;
  padding: 1px 12px;
  line-height: 35px;
  min-width: 100px;
  outline: none;
}

.custom_btn .btn_icon_container {
  color: black;
  background-color: #fff;
  border-radius: 50px !important;
  height: 27px;
  width: 27px;
  line-height: 29px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.custom_btn .btn_icon_container > .MuiSvgIcon-root {
  font-size: 16px;
}
.custom_btn .btn_text {
  font-size: 14px;
  text-transform: none;
  text-align: center;
  width: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

.Covid_container__37YPy {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Covid_image__tfQGh {
  width: 350px;
  margin-bottom: 20px;
}

@media (max-width: 770px) {
  .Covid_container__37YPy {
    margin: 0 10%;
  }
  .Covid_image__tfQGh {
    width: 100%;
  }
}

.Cards_container__3bjn6 {
  margin: 0;
}

.Cards_card__eGUfZ {
  margin: 0 2% !important;
}

.Cards_infected__-adYp {
  border-bottom: 5px solid rgba(0, 0, 255, 0.5);
}

.Cards_recovered__1aizD {
  border-bottom: 5px solid rgba(0, 255, 0, 0.5);
}

.Cards_deaths__Qdjzq {
  border-bottom: 5px solid rgba(255, 0, 0, 0.5);
}

.Chart_containers__12RZG {
  padding-top: 50px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  width: 85% !important;
}

.CountryPicker_formControl__2p8f9 {
  width: 30% !important;
  margin-bottom: 30px !important;
}

.footer {
  background-color: #1e1e1e;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  min-height: 50px;
  border-radius: 8px;
  padding: 20px 25px;
}

.footer .footer_copyright {
  font-size: small;
  text-align: end;
  color: #9c9c9c;
}
.footer .footer_copyright > a {
  text-decoration: none;
  color: var(--main-color);
}
.footer .footer_name {
  font-family: "Kaushan Script";
}

.footer .footer_name > span {
  color: var(--main-color);
}

.header {
  margin-bottom: 30px !important;
  background-color: white;
  padding: 0 22px 0 0 !important;
  border-radius: 6px !important;
  box-shadow: 0px 2px 92px 0px rgba(0, 0, 0, 0.07) !important;
}

.header .header_link {
  font-weight: 500;
  color: darkslategray !important;
}
.header .header_link:hover {
  color: var(--main-color) !important;
}
.header .header_link_active {
  font-weight: 500;
  color: var(--main-color) !important;
}
.header .header_navlink {
  padding: 0;
}
.header .header_home {
  background-color: var(--main-color);
  padding: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center im !important;
          justify-content: center im !important;
  -webkit-align-items: center im !important;
          align-items: center im !important;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.header .header_left {
  margin-right: auto;
}
.header .header_right {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.header .header_right > a > .MuiSvgIcon-root {
  color: black;
  font-size: 22px;
  margin-right: 12px;
}

.profile {
  background-color: white;
  border-radius: 6px;
  width: 100%;
  display: inline-block;
}

.profile_name {
  line-height: 18px;
  padding: 20px;
}
.profile_name .name {
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
}
.profile_name .title {
  font-size: 13px;
  color: #777;
}
.profile_image {
  margin-top: -10px;
  -webkit-clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
  clip-path: polygon(0 9%, 100% 0, 100% 94%, 0% 100%);
}
.profile_image img {
  width: 100%;
}
.profile_information {
  top: 0;
  left: 0;
  margin: -40px 10px 30px;
}

.profile_information .timeline .timeline_content {
  margin-top: 2px;
  margin-bottom: 12px;
}
.timelineitem_text {
  font-size: 13.5px !important;
  color: #787878;
  font-weight: 400 !important;
}

.timelineitem_text > span {
  color: black;
}
.timelineitem_text > a {
  text-decoration: none;
  color: #787878;
}
.timelineitem_text > a:hover {
  text-decoration: none;
  color: var(--main-color);
}
.profile .btn_container {
  width: 100%;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 15px;
}
.profile .btn_container > a {
  text-decoration: none;
}

.profile .btn_container > a :hover {
  text-decoration: none;
}

